/* eslint-disable */
import moment from 'moment';
import constants from '../constants/constants';

/* eslint-disable no-restricted-syntax */
const getInputClasses = (formik, fieldname) => {
  if (formik.touched[fieldname] && formik.errors[fieldname]) {
    return 'error';
  }
  if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    return '';
  }

  return '';
};

const convertDMStoDecimal = (model) => {
  let multiplier = -1;
  let returnVal = 0;

  if (model.degrees > 0) {
    returnVal = model.degrees + model.minutes / 60 + model.seconds / 3600;
    multiplier = 1;
  } else {
    returnVal = (multiplier * model.degrees) + model.minutes / 60 + model.seconds / 3600;
  }

  return multiplier * returnVal;
};

const extractDMSValues = (dmsString) => {
  // const pattern = /(\d+)°\s(\d+)'\s(\d+)"/;
  /* eslint-disable-next-line  */
  const pattern = /^[+-]?(\d+)\s?°\s?(\d+)\s?'\s?(\d+\.?\,?\d*?)"\s?/gm;
  const match = pattern.exec(dmsString);
  if (match) {
    return {
      degrees: match[0] ? parseInt(match[0] + match[1], 10) : parseInt(match[1], 10),
      minutes: parseInt(match[2], 10),
      seconds: parseInt(match[3], 10)
    };
  }
  return null;
};

const convertJsonToFormData = (json) => {
  const form_data = new FormData();
  for (const key in json) {
    if (Array.isArray(json[key])) { json[key].forEach((item) => form_data.append(key, item)); } else { form_data.append(key, (json[key] === null) ? '' : json[key]); }
  }

  return form_data;
};

const setToOptionModel = (name, value) => ({ label: name, value });

const reOrderDnd = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((x, i) => ({ ...x, sortOrder: i }));
};

const mapContentSectionName = (type) => {
  switch (type) {
    case constants.CONTENT_SECTION.ContentSectionTypeDefault:
      return 'Content Section (Default)';
    case constants.CONTENT_SECTION.ContentSectionTypeReversed:
      return 'Content Section (Reversed)';
    case constants.CONTENT_SECTION.ContentSectionTypeFullWidth:
      return 'Content Section Full Width';
    case constants.CONTENT_SECTION.ContentSectionTypeFullWidthPadded:
      return 'Content Section Full Width (Padded)';
    case constants.CONTENT_SECTION.ContentSectionTypeTextOnly:
      return 'Content Section (Text Only)';
    case constants.CONTENT_SECTION.ContentSectionTypeCard:
      return 'Content Card';
    case constants.CONTENT_SECTION.ContentSectionTypeBanner:
      return 'Content Banner';
    case constants.CONTENT_SECTION.ContentSectionDynamicBanner:
      return 'Dynamic Banner';
    default:
      return '';
  }
};

const copyToClipBoard = (copyText) => {
  const aux = document.createElement('input');
  aux.setAttribute('value', copyText);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

const countdownMessage = (expiryDate) => {
  if (!expiryDate) {
    return '';
  }

  const date = moment().format('MM/DD/YYYY hh:mm:ss a');
  const diffr = moment.duration(moment(new Date(expiryDate)).diff(new Date(date)));
  const hours = parseInt(diffr.asHours(), 10);
  const minutes = parseInt(diffr.minutes(), 10);
  const seconds = parseInt(diffr.seconds(), 10);
  const currentDuration = Math.floor((hours * 60 * 60 + minutes * 60 + seconds) / 3600);

  if (currentDuration > 24) {
    return `${Math.ceil(currentDuration / 24)} Days Left`;
  }
  if (currentDuration === 24) {
    return `${Math.floor(currentDuration / 24)} Day Left`;
  }
  if (currentDuration > 12 && currentDuration !== 24) {
    return 'Expires Soon';
  }
  if ((currentDuration < 12 && currentDuration > 0) || currentDuration === 0) {
    return 'Expires Today';
  }
  if (currentDuration === -1 || currentDuration < -1) {
    return '';
  }

  return 'Limited-Time Only';
};

const dateFilterListRenderer = (v) => {
  if (v[0] && v[1]) {
    return `Start Date: ${v[0]} to End Date: ${v[1]}`;
  }
  if (v[0]) {
    return `Start Date: ${v[0]}`;
  }
  if (v[1]) {
    return `End Date: ${v[1]}`;
  }

  return false;
};

const dateFilterLogic = (date, filters) => {
  const check = new Date(date);
  let from = new Date(filters[0]);
  let to = new Date(filters[1]);

  from.setDate(from.getDate() + 1);
  to.setDate(to.getDate() + 1);
  from = new Date(from).setHours(0, 0, 0, 0);
  to = new Date(to).setHours(23, 59, 59, 59);

  if (filters[0] && filters[1] && check >= to && check <= from) {
    return true;
  }
  if (filters[0] && check >= to) {
    return true;
  }
  if (filters[1] && check <= from) {
    return true;
  }
  return false;
};

const daysDifference = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'days');
};

const getQuarterDay = (quarter, day) => {
  // Input validation
  if (!quarter || !day) {
    return '';
  }

  const quarterNum = parseInt(quarter, 10);
  const dayNum = parseInt(day, 10);

  // Validate quarter is between 1-4 and day is between 1-3
  if (isNaN(quarterNum) || quarterNum < 1 || quarterNum > 4 || 
      isNaN(dayNum) || dayNum < 1 || dayNum > 3) {
    return '';
  }

  const quarterDays = [
    ['January', 'February', 'March'],
    ['April', 'May', 'June'],
    ['July', 'August', 'September'],
    ['October', 'November', 'December']
  ];

  try {
    return quarterDays[quarterNum - 1][dayNum - 1];
  } catch (error) {
    console.error('Error in getQuarterDay:', error);
    return '';
  }
};

const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1;
  return Math.ceil(month / 3);
};

const currencyFormat = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(value);
};

const getYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 1970; i <= currentYear; i += 1) {
    years.push(setToOptionModel(i, i));
  }
  return years.sort((a, b) => b.value - a.value);
};

/**
 * A replacer function for JSON.stringify() to handle undefined values.
 * @param {string} key The key in the object being stringified.
 * @param {any} value The value of the key being stringified.
 * @returns {any} The value to be used in the JSON string.
 */
function replacer(key, value) {
  if (value === null || value === undefined) {
    return ''; // Convert undefined or null to an empty string.
  }
  return value;
}

/**
 * Converts an array of objects to a CSV string.
 * @param {Array} data Array of objects to be converted.
 * @param {Array} columnHeaders Array of strings representing the column headers.
 * @returns {string} CSV string.
 */
// Assuming the helper function looks something like this:
const convertToCSV = (data, headers) => {
  const csvRows = [];
  csvRows.push(headers.join(',')); // Create the header row
  data.forEach(row => {
      csvRows.push(headers.map(field => JSON.stringify(row[field], (key, value) => 
          typeof value === 'string' ? value.replace(/"/g, '""') : value)).join(','));
  });
  return csvRows.join('\n');
};

/**
 * Triggers a download of the CSV file.
 * @param {string} csv The CSV string to be downloaded.
 * @param {string} filename The name of the file to be downloaded.
 */
function downloadCSV(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
  getInputClasses,
  convertJsonToFormData,
  setToOptionModel,
  reOrderDnd,
  mapContentSectionName,
  copyToClipBoard,
  convertDMStoDecimal,
  countdownMessage,
  extractDMSValues,
  dateFilterListRenderer,
  dateFilterLogic,
  daysDifference,
  getQuarterDay,
  currencyFormat,
  getCurrentQuarter,
  getYears,
  downloadCSV,
  replacer,
  convertToCSV
};
/* eslint-disable */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
// import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import OverLay from '../../../../components/Overlay';
import SelectBlock from '../../../../components/SelectBlock';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as invoiceService from '../../../../services/payment/invoiceService';
import * as reportService from '../../../../services/report/reportService';
import * as clientService from '../../../../services/management/clientService';
import constants from '../../../../constants/constants';


const STORAGE_KEY = 'invoiceListingFilters';
const TABLE_STORAGE_KEY = 'invoiceListingTableState';

const quaters = [1, 2, 3, 4];

const model = {
  year: new Date().getFullYear(),
  quaters: null,
  clientIds: null,
  hasPaid: false
};

const InvoiceListingPage = (props) => {
  const { showAlert, auth, showConfirmModal, hideConfirmModal } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [clients, setClients] = useState([]);
  const [filterModel, setFilterModel] = useState(() => {
    const savedFilters = localStorage.getItem(STORAGE_KEY);
    return savedFilters ? JSON.parse(savedFilters).filterModel : { ...model };
  });
  const [quaterOptions, setQuaterOptions] = useState([]);
  const [toggleInvoiceListing, setToggleInvoiceListing] = useState(false);
  const [selectedClientOption, setSelectedClientOption] = useState(() => {
    const savedFilters = localStorage.getItem(STORAGE_KEY);
    return savedFilters ? JSON.parse(savedFilters).selectedClientOption : [];
  });

  const [selectedYearOption, setSelectedYearOption] = useState(() => {
    const savedFilters = localStorage.getItem(STORAGE_KEY);
    return savedFilters ? JSON.parse(savedFilters).selectedYearOption : {
      label: new Date().getFullYear(),
      value: new Date().getFullYear()
    };
  });

  const [selectedQuaterOption, setSelectedQuaterOption] = useState(() => {
    const savedFilters = localStorage.getItem(STORAGE_KEY);
    return savedFilters ? JSON.parse(savedFilters).selectedQuaterOption : [];
  });

  const [tableState, setTableState] = useState(() => {
    const savedState = localStorage.getItem(TABLE_STORAGE_KEY);
    return savedState ? JSON.parse(savedState) : null;
  });
  // const iconSize = 22;
  // const iconColor = 'white--clr';

  useEffect(() => {
    const filtersToSave = {
      filterModel,
      selectedClientOption,
      selectedYearOption,
      selectedQuaterOption
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(filtersToSave));
  }, [filterModel, selectedClientOption, selectedYearOption, selectedQuaterOption]);

  useEffect(() => {
    setQuaterOptions(quaters.map((x) => helper.setToOptionModel(x, x)));
    fetchClients();
  }, []);

  useEffect(() => {
    getAllInvoices();
  }, [toggleInvoiceListing]);

  const getAllInvoices = () => {
    setIsLoading(true);
    invoiceService.getAllInvoices(filterModel).then((res) => {
      setInvoices(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const fetchClients = () => {
    clientService.getAllClients(true, auth.clientIds ? auth.clientIds : 'Admin').then((res) => {
      const tempClients = res.map((x) => ({ label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    });
  };

  const sendInvoiceEmail = (invoiceId) => {
    setIsLoading(true);
    invoiceService.sendInvoiceEmail(invoiceId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  // const printAllInvoices = () => {
  //   if (!filterModel.year || !filterModel.quaters || filterModel.quaters.length === 0) {
  //     showAlert({ text: 'Please select both year and quarter before printing.', state: 'warning' });
  //     return;
  //   }

  //   setIsLoading(true);
  //   const year = filterModel.year;
  //   const quarter = filterModel.quaters[0]; // Assuming we're only using the first selected quarter

  //   reportService.printAllInvoices(year, quarter)
  //     .then((data) => {
  //       // Create a blob from the ZIP file data
  //       const blob = new Blob([data], { type: 'application/zip' });
        
  //       // Create a link element, click it, and remove it
  //       const fileURL = URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = fileURL;
  //       link.download = `InvoiceReports_${year}_Q${quarter}.zip`;
  //       link.click();
  //       URL.revokeObjectURL(fileURL);

  //       // Estimate the number of invoices based on the size of the data
  //       // This is a rough estimate and may not be exact
  //       const estimatedInvoiceCount = Math.floor(data.byteLength / 100000); // Assuming average PDF size of 100KB

  //       showAlert({ text: `${estimatedInvoiceCount} invoice reports downloaded successfully.`, state: 'success' });
  //     })
  //     .catch((ex) => {
  //       console.error('Error in printAllInvoices:', ex);
  //       showAlert({ text: `Error downloading invoice reports: ${ex.message}. Please try again.`, state: 'error' });
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  const printAllInvoices = async () => {
    if (!filterModel.year) {
      showAlert({ text: 'Please select a year before printing.', state: 'warning' });
      return;
    }
    setIsLoading(true);
    try {
      const invoices = await reportService.printFilteredInvoices(filterModel);
      
      if (!Array.isArray(invoices) || !invoices.length) {
        showAlert({ text: 'No invoices found for selected filters', state: 'warning' });
        return;
      }
 
      const reportsWindow = window.open('', '_blank');
      const reportBaseUrl = `${constants.API_URL}Report/GetInvoiceReport`;
      
      const iframeHtml = invoices
        .filter(invoice => invoice && invoice.id) 
        .map(invoice => (
          `<div class="report-container">
            <iframe class="report-frame" 
              src="${reportBaseUrl}?InvoiceId=${invoice.id}"
              onload="this.style.height=this.contentWindow.document.body.scrollHeight+'px';">
            </iframe>
          </div>`
        ))
        .join('');
 
      if (reportsWindow?.document) {
        reportsWindow.document.write(`
          <!DOCTYPE html>
          <html>
            <head>
              <title>Invoice Reports</title>
              <style>
                body { margin: 0; padding: 20px; }
                .report-container { margin-bottom: 30px; }
                .report-frame { width: 100%; height: 1100px; border: 1px solid #eee; }
              </style>
            </head>
            <body>${iframeHtml}</body>
          </html>
        `);
        reportsWindow.document.close();
        showAlert({ text: `${invoices.length} invoice reports loaded`, state: 'success' });
      }
    } catch (ex) {
      console.error('Print error:', ex);
      showAlert({ text: 'Error loading reports', state: 'error' });
    } finally {
      setIsLoading(false);
    }
 };

 const resetFilters = () => {
    const defaultYear = new Date().getFullYear();
    setFilterModel({ 
      clientIds: [], 
      year: defaultYear, 
      hasPaid: false, 
      quaters: [] 
    });
    setSelectedClientOption([]);
    setSelectedQuaterOption([]);
    setSelectedYearOption({
      label: defaultYear,
      value: defaultYear
    });
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(TABLE_STORAGE_KEY);
    setToggleInvoiceListing(!toggleInvoiceListing);
  };

  const confirmSendInvoiceEmail = (invoiceId) => {
    showConfirmModal({
      title: 'Send Invoice Email',
      text: 'Are you sure you want to send this invoice email?',
      btnAction: () => {
        sendInvoiceEmail(invoiceId);
        hideConfirmModal();
      },
      rightBtnText: 'Send',
      cancelText: 'Cancel'
    });
  };

  // const handleChange = (date) => {
  //   setSelectedYearOption(date ?? null);
  //   setFilterModel({ ...filterModel, year: date ? date.getFullYear() : null });
  // };

  const columnOptions = {
    filter: false,
    sort: true,
    print: true,
    download: true
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text={auth.accessLevel >= 50 ? 'View' : 'Details'}
              className="primary--bg ml-0"
              size="xxs"
              to={`/payment/make-payment/${invoices[dataIndex].id}/${invoices[dataIndex].clientId}`}
            />
            <Button
              text="Send Email"
              className="secondary--bg ml-2"
              size="xxs"
              onClick={() => confirmSendInvoiceEmail(invoices[dataIndex].id)}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'invoiceNumber',
      label: 'Invoice #',
      options: columnOptions,
      width: 1000
    },
    {
      name: 'year',
      label: 'Year',
      options: {
        filter: true,
        filterType: 'textField',
        sort: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].year ? invoices[dataIndex].year : '-'}</p>
        )
      }
    },
    {
      name: 'quater',
      label: 'Quarter',
      options: {
        filter: true,
        filterType: 'textField',
        sort: false,
        print: true,
        download: true
      }
    },
    {
      name: 'clientName',
      label: 'Client',
      options: {
        filter: true,
        filterType: 'dropDown',
        sort: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].clientName ? invoices[dataIndex].clientName : '-'}</p>
        )
      }
    },
    {
      name: 'contact',
      label: 'Contact',
      options: {
        filter: false,
        sort: false,
        display: 'none',
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].contact ? invoices[dataIndex].contact : '-'}</p>
        )
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        print: true,
        display: 'none',
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].email ? invoices[dataIndex].email : '-'}</p>
        )
      }
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: false,
        print: true,
        display: 'none',
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].phone ? invoices[dataIndex].phone : '-'}</p>
        )
      }
    },
    {
      name: 'lateFeeAmount',
      label: 'Late Fee',
      options: {
        filter: false,
        sort: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].lateFeeAmount ? invoices[dataIndex].lateFeeAmount : '-'}</p>
        )
      }
    },
    {
      name: 'totalAmount',
      label: 'Total Amount',
      options: {
        filter: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{helper.currencyFormat(invoices[dataIndex].totalAmount)}</p>
        )
      },
    },
    {
      name: 'paymentType',
      label: 'Payment Type',
      options: {
        filter: false,
        sort: false,
        print: true,
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].paymentType ? invoices[dataIndex].paymentType : '-'}</p>
        )
      }
    },
    {
      name: 'gallons',
      label: 'Gallons',
      options: {
        filter: false,
        sort: false,
        print: true,
        display: 'none',
        download: true,
        customBodyRenderLite: (dataIndex) => (
          <p>{invoices[dataIndex].gallons ? invoices[dataIndex].gallons : '-'}</p>
        )
      }
    }
  ];

  const tableOptions = {
    ...tableState,
    selectableRows: 'none',
    download: true,
    filterType: 'dropdown',
    print: true,
    onTableChange: (action, tableState) => {
      localStorage.setItem(TABLE_STORAGE_KEY, JSON.stringify(tableState));
    }
  };

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock cl assName="content-container--card-style--with-shadow">
              {/* {
                auth.accessLevel >= 50
                && (
                  <ContentHeader
                    title="Invoices"
                    headerSize="lg"
                    primaryButtonText="New Invoice"
                    primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
                    primaryButtonTo="/invoice/create"
                  />
                )
              } */}
              <CustomBlock>
                <Section hasNoContainer>
                  <ContentHeader
                    title="Filters"
                    headerSize="md"
                  />
                  <ContentBlock cols={4}>
                    <SelectBlock
                      label="Client"
                      placeholder="Select client"
                      options={clients}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      value={selectedClientOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          clientIds: selectedOpt.map((x) => x.value ?? null)
                        });
                        setSelectedClientOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={2}>
                    <SelectBlock
                      label="Quarter"
                      placeholder="Select quarter"
                      options={quaterOptions}
                      isMulti
                      isClearable
                      closeMenuOnSelect={false}
                      value={selectedQuaterOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          quaters: selectedOpt.map((x) => x.value ?? null)
                        });
                        setSelectedQuaterOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock cols={2}>
                    <SelectBlock
                      label="Year"
                      placeholder="Select year"
                      options={helper.getYears()}
                      isClearable
                      closeMenuOnSelect
                      value={selectedYearOption}
                      onChange={(selectedOpt) => {
                        selectedOpt = selectedOpt === null ? null : selectedOpt;
                        setFilterModel({
                          ...filterModel,
                          year: selectedOpt ? selectedOpt.value : null
                        });
                        setSelectedYearOption(selectedOpt);
                      }}
                    />
                  </ContentBlock>
                  <ContentBlock>
                    <CheckboxBlock
                      label="Has Paid?"
                      id="hasPaid"
                      isChecked={filterModel.hasPaid}
                      value={filterModel.hasPaid}
                      onChange={() => {
                        setFilterModel({
                          ...filterModel,
                          hasPaid: !filterModel.hasPaid
                        });
                      }}
                    />
                  </ContentBlock>
                  <CustomBlock style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      text="Reset"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={resetFilters}
                    />
                    &nbsp;
                    <Button
                      text="Search"
                      className="primary--bg ml-0"
                      size="xs"
                      onClick={() => setToggleInvoiceListing(!toggleInvoiceListing)}
                    />
                  </CustomBlock>
                </Section>
                {
                  auth.accessLevel >= 50 &&
                  (<>
                    <CustomBlock style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '2rem' }}>
                      <Button
                        text="Print All"
                        className="secondary--bg ml-0"
                        size="xs"
                        onClick={printAllInvoices}
                      />
                    </CustomBlock>
                  </>)
                }
                <MUIDataTable
                  data={invoices}
                  columns={columns}
                  options={tableOptions}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(InvoiceListingPage);
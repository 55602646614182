/* eslint-disable */
import React from 'react';
import Table from 'react-bootstrap/Table';
import * as helper from '../../../../../helpers/helper';
import _ from 'lodash';

const InvoiceTables = ({ invoice, invoiceItems }) => {
  const renderWellTable = (items, isExempt) => {
    const hasItems = items.some(item => 
      item.invoiceItems.some(lineItem => 
        isExempt ? lineItem.flag === 'E' : lineItem.flag !== 'E'
      )
    );

    if (!hasItems) return null;

    return (
      <>
        <thead>
          <tr className="primary--sbg white--clr">
            <th colSpan={6}>{isExempt ? 'EXEMPT' : 'NON EXEMPT'}</th>
          </tr>
        </thead>
        {items.map((item) => {
          const filteredItems = _.sortBy(item.invoiceItems, lineItem => lineItem.ownerNo)
            .filter((lineItem) => isExempt ? lineItem.flag === 'E' : lineItem.flag !== 'E');

          if (filteredItems.length === 0) return null;

          return (
            <React.Fragment key={item.parish}>
              <thead>
                <tr>
                  <th>Parish</th>
                  <th>Year</th>
                  <th>Quarter</th>
                  <th colSpan={3}>Use</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{item.parish}</td>
                  <td>{invoice?.year}</td>
                  <td>{invoice?.quater}</td>
                  <td colSpan={3}>{item.use}</td>
                </tr>
              </tbody>
              <thead>
                <tr className="success--sbg white--clr">
                  <th>Well No.</th>
                  <th colSpan={2}>Well Depth</th>
                  <th>{helper.getQuarterDay(invoice?.quater, 1)}</th>
                  <th>{helper.getQuarterDay(invoice?.quater, 2)}</th>
                  <th>{helper.getQuarterDay(invoice?.quater, 3)}</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((lineItem) => (
                  <tr key={lineItem.id}>
                    <td>{lineItem.ownerNo}</td>
                    <td colSpan={2}>{lineItem.depth}</td>
                    <td>{lineItem.month1Pumpage}</td>
                    <td>{lineItem.month2Pumpage}</td>
                    <td>{lineItem.month3Pumpage}</td>
                  </tr>
                ))}
              </tbody>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const calculateTotalsByMonth = () => {
    const allItems = _.flatMap(invoiceItems, item => item.invoiceItems);
    
    const totalPumpage = {
      month1: _.sumBy(allItems, 'month1Pumpage'),
      month2: _.sumBy(allItems, 'month2Pumpage'),
      month3: _.sumBy(allItems, 'month3Pumpage'),
      total: _.sumBy(allItems, item => item.month1Pumpage + item.month2Pumpage + item.month3Pumpage)
    };

    const exemptItems = allItems.filter(item => item.flag === 'E');
    const exemptPumpage = {
      month1: _.sumBy(exemptItems, 'month1Pumpage'),
      month2: _.sumBy(exemptItems, 'month2Pumpage'),
      month3: _.sumBy(exemptItems, 'month3Pumpage'),
      total: _.sumBy(exemptItems, item => item.month1Pumpage + item.month2Pumpage + item.month3Pumpage)
    };

    const nonExemptPumpage = {
      month1: totalPumpage.month1 - exemptPumpage.month1,
      month2: totalPumpage.month2 - exemptPumpage.month2,
      month3: totalPumpage.month3 - exemptPumpage.month3,
      total: totalPumpage.total - exemptPumpage.total
    };

    // Convert to millions of gallons for billing
    const billedPumpageMGal = (nonExemptPumpage.total / 1000).toFixed(3);
    const dollarAmount = (billedPumpageMGal * 65).toFixed(2);

    return {
      totalPumpage,
      exemptPumpage,
      nonExemptPumpage,
      billedPumpageMGal,
      dollarAmount
    };
  };

  const summaryData = calculateTotalsByMonth();

  return (
    <>
      <Table striped bordered hover className="text-center">
        {/* Non-Exempt Table */}
        {renderWellTable(invoiceItems, false)}
        
        {/* Exempt Table */}
        {renderWellTable(invoiceItems, true)}
      </Table>

      {/* Summary Table */}
      <Table striped bordered hover className="text-center mt-4">
        <thead>
          <tr>
            <th></th>
            <th>{helper.getQuarterDay(invoice?.quater, 1)}</th>
            <th>{helper.getQuarterDay(invoice?.quater, 2)}</th>
            <th>{helper.getQuarterDay(invoice?.quater, 3)}</th>
            <th>Total (kgal)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Pumpage</td>
            <td>{Math.round(summaryData.totalPumpage.month1)}</td>
            <td>{Math.round(summaryData.totalPumpage.month2)}</td>
            <td>{Math.round(summaryData.totalPumpage.month3)}</td>
            <td>{Math.round(summaryData.totalPumpage.total)}</td>
          </tr>
          <tr>
            <td>Exempt</td>
            <td>{Math.round(summaryData.exemptPumpage.month1)}</td>
            <td>{Math.round(summaryData.exemptPumpage.month2)}</td>
            <td>{Math.round(summaryData.exemptPumpage.month3)}</td>
            <td>{Math.round(summaryData.exemptPumpage.total)}</td>
          </tr>
          <tr>
            <td>Non Exempt</td>
            <td>{Math.round(summaryData.nonExemptPumpage.month1)}</td>
            <td>{Math.round(summaryData.nonExemptPumpage.month2)}</td>
            <td>{Math.round(summaryData.nonExemptPumpage.month3)}</td>
            <td>{Math.round(summaryData.nonExemptPumpage.total)}</td>
          </tr>
          <tr>
            <td colSpan={2}>Billed Pumpage (mgal)</td>
            <td colSpan={2}>{summaryData.billedPumpageMGal}</td>
            <td>${summaryData.dollarAmount}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default InvoiceTables;
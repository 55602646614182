/* eslint-disable */
// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
import { Alert } from '@material-ui/lab';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../../../components/CustomBlock';
import * as helper from '../../../../../helpers/helper';
import InfoBlock from '../../../../../components/InfoBlock';
import Button from '../../../../../components/Button';
// REDUX
// SERVICES AND HELPERS

const PaymentModule = (props) => {
  const {
    auth,
    paymentIntent,
    invoiceId,
    invoice,
    paymentType = 'card'
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: `${window.location.origin}/client/payment/invoice-confirmation/${auth.clientIds}/${invoiceId}/${paymentIntent.id}` },
    }).finally(() => {
      setIsLoading(false);
    });

    if (result.error) {
      console.log(result)
      setErrorMessage(result.error.message);
    }
  };

  return (
    <CustomBlock className="content-container--padded">
      {
        errorMessage && (
          <Alert className="mb-15" severity="error">{errorMessage}</Alert>
        )
      }
      <form onSubmit={handleSubmit}>
        <PaymentElement
          options={{
            paymentMethodOrder: [paymentType],
            defaultValues: {
              billingDetails: {
                name: `${auth.user.firstName} ${auth.user.lastName}`,
                email: invoice.clientEmail
              }
            }
          }}
        />

        <CustomBlock className="content-container--actions mt-20">
          <Button
            type="submit"
            text={isLoading ? 'Processing...' : paymentType === 'card' ? 'Pay Now' : 'Pay by Bank Transfer'}
            className="success--bg mt-10"
            isDisabled={isLoading}
          />
        </CustomBlock>
      </form>
    </CustomBlock>
  );
};

export default PaymentModule;
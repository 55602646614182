/* eslint-disable */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'Report/';

const getInvoiceReport = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetInvoiceReport`, model, constants.REQUIRE_INTERCEPTORS);

// const printAllInvoices = async (year, quarter) => {
//   try {
//     console.log(`Requesting invoices for year ${year}, quarter ${quarter}`);
//     const response = await axiosInstance.get(`${ENDPOINTORIGIN}GetBulkInvoiceReports?year=${year}&quarter=${quarter}`, {
//       ...constants.REQUIRE_INTERCEPTORS,
//       responseType: 'arraybuffer',
//       responseHandlerEnabled: true  // This is important
//     });
    
//     console.log('Response received:', response);
//     console.log('Response type:', typeof response);
//     console.log('Is ArrayBuffer:', response instanceof ArrayBuffer);
    
//     if (!(response instanceof ArrayBuffer)) {
//       throw new Error('Unexpected response format from the server');
//     }
    
//     console.log('Response length:', response.byteLength);
//     return response;
//   } catch (error) {
//     console.error('Error in printAllInvoices service:', error);
//     if (error.response) {
//       console.log('Error response:', error.response);
//       if (error.response.data instanceof ArrayBuffer) {
//         const textDecoder = new TextDecoder('utf-8');
//         const errorText = textDecoder.decode(error.response.data);
//         console.log('Error response data (decoded):', errorText);
//       } else {
//         console.log('Error response data:', error.response.data);
//       }
//     }
//     throw error;
//   }
// };

const printFilteredInvoices = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}GetFilteredInvoiceReports`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getInvoiceReport,
  printFilteredInvoices
};
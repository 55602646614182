/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable quotes */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// COMPONENTS
// import MUIDataTable from 'mui-datatables';
// ICONS
// import { IoMdAddCircle } from 'react-icons/io';
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import InputBlock from '../../../../components/InputBlock';
import SelectBlock from '../../../../components/SelectBlock';
// REDUX
import * as auth from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';
import * as wellDetailRedux from '../../../../redux/wellDetailRedux';
// SERVICES AND HELPERS
import WellValidator from '../../../../helpers/validators/well/wellValidator';
import * as wellService from '../../../../services/well/wellService';
import * as wellUseService from '../../../../services/well-use/wellUseService';
import * as aquiferService from '../../../../services/management/aquiferService';
import * as parishService from '../../../../services/management/parishService';
import * as clientService from '../../../../services/management/clientService';
import * as helper from '../../../../helpers/helper';

const initialModel = {
  id: 0,
  clientId: '',
  aquiferId: '',
  wellUseId: '',
  parishId: '',
  identificationNumber: '',
  wellNumber: '',
  permitNumber: null,
  wellDepth: 0,
  dailyPumpRate: null,
  clientWellName: '',
  isRegistered: false,
  availableInformation: '',
  latitude: '',
  longitude: '',
  flag: '',
  isActive: true,
  completionDateTime: '',
  inactiveDateTime: '',
  cagcdPermitDate: '',
  ldnrNotificationDate: '',
  wellStatusDate: '',
  wellStatus: '',
  meterType: '',
  ldnrNotificationNumber: 0,
  cagcdPermitNumber: '',
  comment: '',
  aquifer: {},
  wellUse: {},
  client: {}
};

const wellStatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

/* eslint-disable-next-line  */
// const mask = [/^[+-]?(\d+)\s?°\s?(\d+)\s?'\s?(\d+\.?\,?\d*?)"\s?/gm];

const WellManagementPage = (props) => {
  const {
    showAlert,
    history,
    auth,
  } = props;
  const { wellId } = useParams();
  const [well, setWell] = useState(initialModel);
  const [isLoading, setIsLoading] = useState(false);
  const [wellUses, setWellUses] = useState([]);
  const [clients, setClients] = useState([]);
  const [aquifers, setAquifers] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [selectedAquiferOptions, setSelectedAquiferOptions] = useState([]);
  const [selectedParishOptions, setSelectedParishOptions] = useState([]);
  const [selectedClientOption, setSelectedClientOption] = useState([]);
  const [selectedWellUseOption, setSelectedWellUseOption] = useState([]);
  const [isClientLoading, setIsClientLoading] = useState(false);
  const [permitDate, setPermitDate] = useState('');
  const [completedDate, setCompletedDate] = useState('');
  const [ldnrNotificationDate, setLDNRNotificationDate] = useState('');
  const [wellStatusDate, setWellStatusDate] = useState('');
  const [isAquiferLoading, setIsAquiferLoading] = useState(false);
  const [isParishLoading, setIsParishLoading] = useState(false);
  const [isWellUseLoading, setIsWellUseLoading] = useState(false);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  useEffect(() => {
    if (wellId) {
      populateForm(wellId);
    }
    if (auth.accessLevel >= 20) {
      fetchClients();
      fetchAquifers();
      fetchParishes();
      fetchWellUses();
    }
  }, []);

  const handleWellStatusChange = (selectedOption) => {
    const currentDate = new Date().toISOString();
    formik.setFieldValue("wellStatus", selectedOption.value);
    formik.setFieldValue("wellStatusDate", currentDate);
    setWellStatusDate(currentDate);
  };

  const populateForm = (id) => {
    setIsLoading(true);
    wellService.getWellById(auth.clientIds ? parseInt(auth.clientIds, 10) : '', id).then((res) => {
      setWell(res);
      if (res.client) {
        setSelectedClientOption({ label: res.client.name, value: res.client.id });
      }
      if (res.wellUse) {
        setSelectedWellUseOption({ label: res.wellUse.description, value: res.wellUse.id });
      }
      if (res.aquifer) {
        setSelectedAquiferOptions({ label: res.aquifer.aquiferId, value: res.aquifer.id });
      }
      if (res.parish) {
        setSelectedParishOptions({ label: res.parish.name, value: res.parish.id });
      }
      setLatitude(res.latitude);
      setLongitude(res.longitude);
      setCompletedDate(res.completionDateTime);
      setPermitDate(res.cagcdPermitDate);
      setLDNRNotificationDate(res.ldnrNotificationDate);
      setWellStatusDate(res.wellStatusDate);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleLongitudeChange = (e) => {
    const newValue = formatLongitudeLatitude(e.target.value);
    setLongitude(newValue);
    formik.setFieldValue('longitude', newValue);
  };

  const handleLatitudeChange = (e) => {
    const newValue = formatLongitudeLatitude(e.target.value);
    setLatitude(newValue);
    formik.setFieldValue('latitude', newValue);
  };

  const formatLongitudeLatitude = (value) => {
    const regex = /^[-+]?[0-9]{1,3}(°[0-9]{1,2}'[0-9]{1,2}.[0-9]{1,2}")?$/;
    if (regex.test(value)) {
      return value;
    }

    let sliceIndex = 3;
    let newValue = value.replace(/[^\d-]/g, "");
    if (!newValue.startsWith('-')) {
      sliceIndex = 2;
    }
    if (newValue.length > sliceIndex) {
      newValue = newValue.slice(0, sliceIndex) + "°" + newValue.slice(sliceIndex);
    }
    if (newValue.length > (sliceIndex + 3)) {
      newValue = newValue.slice(0, (sliceIndex + 3)) + "'" + newValue.slice((sliceIndex + 3));
    }
    if (newValue.length >= (sliceIndex + 6)) {
      newValue = newValue.slice(0, (sliceIndex + 6)) + "\"";
    }
    return newValue;
  };

  const fetchClients = () => {
    setIsClientLoading(true);
    clientService.getAllClients(true, auth.clientIds ? auth.clientIds : 'Admin').then((res) => {
      const tempClients = res.map((x) => ({ ...x, label: x.name, value: x.id }));
      setClients(tempClients);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsClientLoading(false);
    });
  };

  const fetchWellUses = () => {
    setIsWellUseLoading(true);
    wellUseService.getWellUses().then((res) => {
      const tempWellUses = res.map((x) => ({ ...x, label: `${x.wellUseCategory.description}- ${x.wellUseId}`, value: x.id }));
      setWellUses(tempWellUses);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsWellUseLoading(false);
    });
  };

  const fetchAquifers = () => {
    setIsAquiferLoading(true);
    aquiferService.getAquifers().then((res) => {
      const tempAquifers = res.map((x) => ({ ...x, label: x.aquiferId, value: x.id }));
      setAquifers(tempAquifers);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsAquiferLoading(false);
    });
  };

  const fetchParishes = () => {
    setIsParishLoading(true);
    parishService.getParishes().then((res) => {
      const tempParishes = res.map((x) => ({ ...x, label: x.name, value: x.id }));
      setParishes(tempParishes);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsParishLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: well,
    validationSchema: WellValidator,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      saveWell(values);
    },
  });

  const saveWell = (model) => {
    setIsLoading(true);
    wellService.saveWell({
      ...model,
      cagcdPermitDate: permitDate,
      ldnrNotificationDate,
      completionDateTime: completedDate,
      wellStatusDate
    }).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      if (model.id === 0) {
        history.replace(`/well/edit/${res.id}`);
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  // const iconSize = 22;
  // const iconColor = 'white--clr';

  return (
    <>
      {(isLoading || isClientLoading || isAquiferLoading || isWellUseLoading || isParishLoading) && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <FormBlock className="content-container--card-style--with-shadow">
            <ContentHeader
              title="Well Details"
              headerSize="lg"
            // primaryButtonText="Add New Client"
            // primaryButtonIconLeft={<IoMdAddCircle className={iconColor} size={iconSize} />}
            // primaryButtonOnClick={() => {
            //   history.push('/management/client');
            // }}
            />
            <Section hasNoContainer>
              {/* WELL BASIC INFO */}
              <ContentBlock cols={4}>
                <InputBlock
                  label="Well #"
                  isRequired
                  errorMessage={formik.errors.wellNumber}
                  inputState={wellId ? 'disabled' : `${helper.getInputClasses(formik, 'wellNumber')}`}
                  {...formik.getFieldProps('wellNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="USGS Site Code"
                  isRequired
                  errorMessage={formik.errors.identificationNumber}
                  inputState={auth.accessLevel < 50 ? 'disabled' : `${helper.getInputClasses(formik, 'identificationNumber')}`}
                  {...formik.getFieldProps('identificationNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Appl No"
                  errorMessage={formik.errors.permitNumber}
                  inputState={auth.accessLevel < 50 ? 'disabled' : `${helper.getInputClasses(formik, 'permitNumber')}`}
                  maxLength={10}
                  {...formik.getFieldProps('permitNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Well Owner"
                  placeholder="Select client"
                  options={clients}
                  isRequired
                  isClearable
                  closeMenuOnSelect={false}
                  inputState={formik.errors.clientId ? 'error' : ''}
                  errorMessage={formik.errors.clientId}
                  value={selectedClientOption}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedClientOption(selectedOpt);
                    formik.setFieldValue('clientId', selectedOpt ? selectedOpt.id : null);
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Aquifers"
                  placeholder="Select aquifer"
                  options={aquifers}
                  isClearable
                  closeMenuOnSelect={false}
                  value={selectedAquiferOptions}
                  inputState={formik.errors.aquiferId ? 'error' : ''}
                  errorMessage={formik.errors.aquiferId}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedAquiferOptions(selectedOpt);
                    formik.setFieldValue('aquiferId', selectedOpt ? selectedOpt.id : null);
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Parishes"
                  placeholder="Select parish"
                  options={parishes}
                  isClearable
                  closeMenuOnSelect={false}
                  value={selectedParishOptions}
                  inputState={formik.errors.parishId ? 'error' : ''}
                  errorMessage={formik.errors.parishId}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedParishOptions(selectedOpt);
                    formik.setFieldValue('parishId', selectedOpt ? selectedOpt.id : null);
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Well-Use"
                  placeholder="Select well-use"
                  options={wellUses}
                  isClearable
                  closeMenuOnSelect={false}
                  value={selectedWellUseOption}
                  inputState={formik.errors.wellUseId ? 'error' : ''}
                  errorMessage={formik.errors.wellUseId}
                  onChange={(selectedOpt) => {
                    selectedOpt = selectedOpt === null ? null : selectedOpt;
                    setSelectedWellUseOption(selectedOpt);
                    formik.setFieldValue('wellUseId', selectedOpt ? selectedOpt.id : null);
                  }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Owner Well Name"
                  errorMessage={formik.errors.clientWellName}
                  inputState={`${helper.getInputClasses(formik, 'clientWellName')}`}
                  {...formik.getFieldProps('clientWellName')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Well Depth"
                  errorMessage={formik.errors.wellDepth}
                  inputState={`${helper.getInputClasses(formik, 'wellDepth')}`}
                  {...formik.getFieldProps('wellDepth')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Daily Pump Rate"
                  errorMessage={formik.errors.dailyPumpRate}
                  inputState={`${helper.getInputClasses(formik, 'dailyPumpRate')}`}
                  {...formik.getFieldProps('dailyPumpRate')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Available Information"
                  errorMessage={formik.errors.availableInformation}
                  inputState={`${helper.getInputClasses(formik, 'availableInformation')}`}
                  {...formik.getFieldProps('availableInformation')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Latitude"
                  mask={/^[-+]?[0-9]{1,2}(°[0-9]{1,2}'[0-9]{1,2}.[0-9]{1,2}")$/}
                  maskChar="_"
                  value={latitude}
                  errorMessage={formik.errors.latitude}
                  inputState={`${helper.getInputClasses(formik, 'latitude')}`}
                  onChange={handleLatitudeChange}
                // {...formik.getFieldProps('latitude')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Longitude"
                  mask={/^[-+]?[0-9]{1,2}(°[0-9]{1,2}'[0-9]{1,2}.[0-9]{1,2}")$/}
                  maskChar="_"
                  value={longitude}
                  errorMessage={formik.errors.longitude}
                  inputState={`${helper.getInputClasses(formik, 'longitude')}`}
                  onChange={handleLongitudeChange}
                // {...formik.getFieldProps('longitude')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Flag"
                  errorMessage={formik.errors.flag}
                  inputState={`${helper.getInputClasses(formik, 'flag')}`}
                  {...formik.getFieldProps('flag')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Comment"
                  errorMessage={formik.errors.comment}
                  inputState={`${helper.getInputClasses(formik, 'comment')}`}
                  {...formik.getFieldProps('comment')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="CAGCD Permit Number"
                  errorMessage={formik.errors.cagcdPermitNumber}
                  inputState={`${helper.getInputClasses(formik, 'cagcdPermitNumber')}`}
                  {...formik.getFieldProps('cagcdPermitNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  type="date"
                  label="CAGCD Permit Date"
                  placeholder="Select Permit Date"
                  onChange={(e) => setPermitDate(e.length > 0 && e[0])}
                  value={permitDate}
                // options={{ dateFormat: 'm/d/Y' }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  type="date"
                  label="LDNR Notification Date"
                  placeholder="Select LDNR Notification Date"
                  onChange={(e) => setLDNRNotificationDate(e.length > 0 && e[0])}
                  value={ldnrNotificationDate}
                // options={{ dateFormat: 'm/d/Y' }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="LDNR Notification Number"
                  errorMessage={formik.errors.ldnrNotificationNumber}
                  inputState={`${helper.getInputClasses(formik, 'ldnrNotificationNumber')}`}
                  {...formik.getFieldProps('ldnrNotificationNumber')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  type="date"
                  label="Completion Date"
                  placeholder="Select Completed Date"
                  onChange={(e) => setCompletedDate(e.length > 0 && e[0])}
                  value={completedDate}
                // options={{ dateFormat: 'm/d/Y' }}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  label="Meter Type"
                  errorMessage={formik.errors.meterType}
                  inputState={`${helper.getInputClasses(formik, 'meterType')}`}
                  {...formik.getFieldProps('meterType')}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <SelectBlock
                  label="Well Status"
                  placeholder="Select Well Status"
                  options={wellStatusOptions}
                  value={wellStatusOptions.find((option) => option.value === formik.values.wellStatus)}
                  inputState={formik.errors.wellStatus ? 'error' : ''}
                  errorMessage={formik.errors.wellStatus}
                  onChange={handleWellStatusChange}
                />
              </ContentBlock>

              <ContentBlock cols={4}>
                <InputBlock
                  type="date"
                  label="Well Status Date"
                  value={wellStatusDate}
                  readOnly
                />
              </ContentBlock>

              <ContentBlock className="content-container--actions flex-end">
                <Button
                  text="Cancel"
                  className="danger--bg"
                  onClick={() => history.goBack()}
                />

                {
                  auth.accessLevel >= 50
                  && (
                    <Button
                      text={wellId ? 'Save Changes' : 'Create'}
                      className="primary--bg"
                      type="submit"
                      onClick={formik.handleSubmit}
                    />
                  )
                }
              </ContentBlock>

            </Section>
          </FormBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...alert.actions, ...confirmModal.actions, ...wellDetailRedux.actions, ...auth.actions })(WellManagementPage);